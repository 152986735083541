.experience__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.experience__container > div {
    background: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.experience__container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.experience__container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}

.experience__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
}

.experience__details {
    display: flex;
    gap: 1rem;
}

.experience__details-icon-react {
    margin-top: 6px;
    color: var(--color-primary);
}

.experience__details-icon-js {
    margin-top: 6px;
    color: #63a814;
}

.experience__details-icon-css {
    margin-top: 6px;
    color: #0070ba;
}

.experience__details-icon-html {
    margin-top: 6px;
    color: #e44d26;
}

.experience__details-icon-csharp {
    margin-top: 6px;
    color: #9f73d5;
}

.experience__details-icon-dotnet {
    margin-top: 6px;
    color: #4e2acd;
}

.experience__details-icon-python {
    margin-top: 6px;
    color: #f5d955;
}

.experience__details-icon-kubernetes {
    margin-top: 6px;
    color: #2e6de7;
}

.experience__details-icon-docker {
    margin-top: 6px;
    color: #129fdb;
}



/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================ */
@media screen and (max-width: 1024px) {
    .experience__container {
        grid-template-columns: 1fr;
    }

    .experience__container > div {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }

    .experience__content {
        padding: 1rem;
    }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================ */
@media screen and (max-width: 600px) { 
    .experience__container {
        gap: 1rem;
    }

    .experience__container > div {
        width: 100%;
        padding: 2rem 1rem;
    }
}